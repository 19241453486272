@import "../../styles/colors"
@import "./attribute_list"

.settings-email-form
  .toggle
    position: relative
    top: 2px
  p.explainer
    padding: 30px 0 10px 0
  .attribute-list
    padding: 0 20px
    .attribute-list__item:first-child
      border-top: 1px solid $grey_ea
    .attribute-list__item:last-child
      border-bottom: 1px solid $grey_ea
