@import "../../styles/colors.sass";

$fa_check: "\f00c";

.flat-checkbox {
  display: inline-block;
  position: relative;
  user-select: none;

  > input {
    display: none;
  }

  &:focus, &:focus-visible {
    outline: 3px dotted $green;
  }
}

$h: 20px;

.flat-checkbox-label {
  display: block;
  cursor: pointer;
  padding: 0;
  padding-left: 30px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: $h;
    height: $h;
    margin: 0;
    border: 1px solid $black;
    border-color: $grey;
  }
}

input:checked + .flat-checkbox-label::before {
  font: normal 16px "Font Awesome 6 Pro";
  font-weight: 900;
  content: $fa_check;
  padding-top: 1px;
  padding-left: 2px;
  color: #666;
}
