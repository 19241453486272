@import "../styles/attribute_list"

.account_checkup
  padding-top: 120px
  padding-bottom: 310px
  max-width: 616px
  margin: auto
  h1
    text-align: center
    font-size: 40px

.item
  @include attribute_list_item
  border-top: 1px solid $grey_ea
  padding-left: 16px
  padding-right: 16px
  &:last-child
    border-top: none
  &:only-child
    border-top: 1px solid $grey_ea
  span
    padding-right: 20px
.value
  display: flex
  justify-content: space-between
  align-items: center
.control
  width: 50px
  text-align: right
  font-size: 14px
  *
    margin-left: auto
.display
  padding: 0
.prompt
  color: $grey_6c
  font-size: 16px
  margin-top: 40px
  margin-bottom: 24px
  padding-left: 16px
  padding-right: 16px

.page
  .buttons
    padding-top: 32px
