@import "../../styles/colors.sass";

@mixin caps {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}

@mixin checkmark {
  transition: opacity 0.3s;
  opacity: 0;
  content: "\f00c";
  font: 14px "Font Awesome 6 Pro";
  font-weight: 900;
  position: absolute;
  top: 14px;
  right: 12px;
  color: $status_green;
  z-index: 2;
}
