@import ../../../styles/colors

.third_party_consent
  border: 2px solid $white
  &.field-error
    border-color: $invalid_red

.consent_row
  background: $grey_f8
  display: flex
  align-items: center
  padding: 20px
  @media screen and (max-width: 480px)
    flex-direction: column

.consent_logo
  width: 200px
  @media screen and (max-width: 480px)
    img
      display: block
      margin-left: auto
      margin-right: auto

.consent_explainer
  margin-bottom: 10px

.consent_checkbox
  input
    margin-left: 0
    margin-right: 0.5rem

.consent_rhs
  font-size: 14px
