@import "../../styles/colors.sass";
@import "./mixins.scss";

.gen-form {
  lost-utility: clearfix;
}

/* The radio button group used to select a form type */
.form-selector {
  padding-bottom: 20px;
  border-bottom: 1px solid $grey;
  margin-bottom: 20px;
}

input[type="radio"] {
  vertical-align: top;
  margin: 1px 10px 0 0;
}

hr.faint {
  border: none;
  border-bottom: 1px solid $grey;
  margin: 20px 0;
}

.fieldgroup {
  clear: fix;
  padding: 20px 0;
  border-bottom: 1px solid $grey;

  form > &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &.buttons {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
    .form_cancel {
      margin-right: 10px;
    }
    .left-button {
      margin-left: 10px;
      margin-right: auto;
    }
    .form_cancel {
      margin-right: 24px;
    }
    .form_cancel,.left-button {
      @include caps;
      font-weight: 600
    }
  }
}

.submit {
  /*float: right;*/
}

.cc-date .form-selectbox {
  float: left;
  width: 50%;

  &:first-child {
    padding-right: 10px;
  }

  &:last-child {
    padding-left: 10px;
  }
}

.radio {
  display: block;
  @include caps;
  font-weight: 400;

  input[type="radio"] {
    margin-right: 10px;
  }
}

form {
  .row {
    lost-utility: clearfix;
  }

  .row:first-child {
    margin-top: 0;
  }
}

.inline-group {
  display: inline-block;
  width: calc(50% - 10px);

  &.lhs {
    margin-right: 10px;
  }
  &.rhs {
    margin-left: 10px;
  }

  input[name="cvv"],
  input[name="new_cvv"] {
    max-width: 150px;
  }
}

.field-with-help {
  position: relative;

  .field-help {
    position: absolute;
    top: 2px;
    right: 0;
  }

  label {
    width: 100%;
  }
}

.field {
  display: inline-block;
  vertical-align: top;
  margin-top: 12px;

  &.grid2 {
    lost-column: 2/12;
  }

  &.grid3 {
    lost-column: 3/12;
  }

  &.grid4 {
    lost-column: 4/12;
  }

  &.grid6 {
    lost-column: 6/12;
  }

  &.grid8 {
    lost-column: 8/12;
  }

  &.grid12,
  &.wide {
    lost-column: 12/12;
  }
}

.caption {
  @include caps;
  margin-bottom: 3px;
  position: relative;

  .tip {
    position: absolute;
    top: -1px;
  }
}

.form-tip {
  i {
    color: #3AA48B;
    margin-left: 8px;
  }
}

.selectbox.focus,
.form-input:focus {
  border-color: $black;
}

.selectbox {
  width: 100%;
}

.form-error {
  display: flex;
  align-items: center;
  border: 2px solid $status_red;
  margin: 20px 0 20px 0;
  padding: 10px;
  text-align: center;
  line-height: 20px;
  vertical-align: middle;
}

.error-icon {
  width: 45px;
  color: $status_red;
  margin-right: 10px;
  font-size: 20px;
  line-height: 24px;
  vertical-align: middle;
}

.error-text {
  display: inline-block;
  width: calc(100% - 45px);
  text-align: left;
}

.error_msg {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  background: $invalid_red;
  color: $white;
  @include caps;
}

.form-error-exit {
  max-height: 40px;
  opacity: 1;

  &.form-error-exit-active {
    transition: all 0.3s ease-in-out;
    max-height: 0;
    opacity: 0;
  }
}

.form-error-enter {
  max-height: 0;
  opacity: 0;

  &.form-error-enter-active {
    transition: all 0.3s ease-in-out;
    max-height: 40px;
    opacity: 1;
  }
}

.field-error {
  input,
  input:focus,
  .passwd-show,
  .dollar-sign,
  .selectbox {
    border-color: $invalid_red;
  }
  .dollar-sign {
    border-right-color: $grey;
  }
  &.password-field {
    input {
      border-right-color: $grey;
    }
    .passwd-show {
      border-left-color: $grey;
    }
  }
}
.new-password-field {
  lost-column: 12/12;
}

.create-password-field {
  margin-top: 0;
  position: relative;
}

.pw-reqs__items-modal {
  display: flex;
  background: $grey_f9;
  padding: 0 10px 10px 10px;

  > div {
    width: 50%;
  }
  @media screen and (max-width: 479px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
}

.pw-reqs-tooltip {
  border: 1px solid $grey_cc;
  background: $white;
  @media screen and (max-width: 479px) {
    background: $grey_f9;
    border-color: $grey_f9;
  }
  @media screen and (min-width: 479px) {
    position: absolute;
    top: -155px;
    z-index: 3;

    &:after, &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(213, 213, 213, 0);
      border-top-color: $white;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
      border-color: rgba(0, 0, 0, 0);
      border-top-color: $grey_cc;
      border-width: 11px;
      margin-left: -11px;
    }
  }
}

.pw-reqs__items {
  margin: 0px 15px 10px;
  @media screen and (min-width: 479px) {
    margin: 20px 10px;
  }
}

.pw-reqs__item {
  padding: 2px 0;
  font-size: 12px;
  i {
    margin-right: 5px;
    text-decoration: none;
    position: relative;
    top: 1px;
  }
}

.pw-reqs__message {
  padding: 10px;
  font-size: 14px;
  background: $grey_f9;
}

.pw-reqs__item-valid {
  color: $valid;
  text-decoration: line-through;
}

.pw-reqs__item-invalid {
  color: $invalid_red;
}

.error-container {
  &::before {
    @include checkmark
  }
  &.field-ready::before {
    opacity: 1;
  }
  &.field-ready.no-check::before {
    opacity: 0;
  }
  &.password-field::before {
    right: 100px;
  }
  &.select-field::before {
    right: 52px;
  }
  &.checkbox-field::before {
    display: none;
  }
  &.toggle::before {
    top: 2px;
    right: 30px;
  }
  &.toggle.no-check::before {
    display: none;
  }
}

/* No checkmark for signin form */
.account_signin_form .error-container::before {
  display: none;
}

.form-input,
.form-selectbox,
.curr-input,
.dollar-sign,
.passwd-input {
  width: 100%;
  border: 1px solid $grey;
  height: 40px;
  font-size: 16px;
  padding-left: 10px;

  &.has_error {
    border-color: $invalid_red;
  }
}

$dollar_width: 30px;

.curr-input {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - $dollar_width);
  border-left: 0;
}

.dollar-sign {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  padding-left: 0;
  width: $dollar_width;
  line-height: 38px;
}

$toggle_width: 90px;

.passwd-input {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - $toggle_width);
}

.passwd-show {
  display: inline-block;
  height: 40px;
  vertical-align: top;
  padding-top: 9px;
  padding-left: 9px;
  border: 1px solid $grey;
  border-left-width: 0;
  width: $toggle_width;
  @include caps;

  .flat-checkbox-label {
    line-height: 22px;
  }
}

.password-quality-meter {
  position: absolute;
  top: 37px;
  left: 0;
  width: calc(100% - $toggle_width);
  height: 3px;
  line-height: 0;

  .bar {
    display: inline-block;
    width: 25%;
    height: 3px;
    background-color: $valid;
  }

  .bar + .bar {
    width: calc(25% - 3px);
    margin-left: 3px;
  }
}

.is-disabled {
  opacity: 0.4;
}

.opt-field {
  color: $dgrey;
}

.align-text-field {
  display: inline-block;
  margin-top: 10px;
}

.help-sub {
  display: block;
  font-size: 12px;
  margin-top: 6px;
  color: $dgrey;
  lost-utility: clearfix;
}

.form-title {
  font-weight: bold;
  font-size: 22px;
  padding: 0 0 15px;
  border-bottom: 1px solid $grey;
}

button.add_more {
  margin-top: 12px;
}

.field-static-text {
  display: inline-block;
  position: relative;
  top: 45px;
  right: 15px;
}

.field-static-text-lm {
  @extend .field-static-text;
  right: auto;
}

.mail-account-field__holder {
  display: flex;
  p {
    flex-shrink: 0;
    font-size: 16px;
    color: $grey_69;
    border: 1px solid $grey;
    border-left: none;
    height: 40px;
    padding-right: 10px;
    line-height: 40px;
    &.focus {
      border-color: $black;
    }

    &.error {
      border-color: $status_red;
    }
  }
}

.mail-account-field__input {
  position: relative;
  width: 100%;
  &::before {
    @include checkmark
  }

  &.ready::before {
    opacity: 1;
  }

  input {
    border-right: none;
  }
}

.mail-account-field__helper-text{
  color: $grey_69;
  margin-top: 0.5em;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 5px;
}

@media screen and (max-width: 479px) {
  .signin,
  .signup,
  .results .left {
    padding: 0;

    .row {
      margin: 0;
    }

    .inline-group {
      display: block;
      width: 100%;

      &.lhs,
      &.rhs {
        margin: 0;
      }

      input[name="cvv"],
      input[name="new_cvv"] {
        max-width: none;
      }
    }

    .field {
      margin-top: 10px;
    }

    .field.grid2 {
      lost-column: 5/12;
    }

    .field.grid3 {
      lost-column: 6/12;
    }

    .field.grid4 {
      lost-column: 12/12;
    }

    .field.grid6 {
      lost-column: 12/12;
    }
  }

  .mail-account-field {
    .error_msg {
      height: auto;
    }
    .form-error-enter-active {
      max-height: 50px;
    }
  }
}
