@import "../../styles/colors.sass";

.cc-terms-info {
  padding-bottom: 10px;
  h2 {
    margin-top: 0px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $grey_ea;
  }
}

.cc-terms-info__section {
  margin-bottom: 20px;
  h3 {
    padding: 10px 0;
  }

  p {
    /* padding-bottom: 10px; */
    color: $grey_99;
    font-size: 14px;
  }
}

.cc-terms-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid $grey_ea;

  .cancel-button {
    margin-right: 10px;
  }
}


