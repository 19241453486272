.verification_holder
  background-color: #F6F7F9

.verification_input
  display: flex
  justify-content: center
  align-items: center
  border: none
  padding-bottom: 64px

.input,.input:disabled
  height: 64px
  width: 64px
  border-radius: 2px
  background-color: white
  font-weight: 500
  font-size: 40px
  line-height: 48px
  text-align: center
  color: #191D21
  border: 1px solid #D9DBDD
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  margin: 0 8px
  -webkit-text-fill-color:#191D21
  -webkit-opacity: 1
  padding: 0
  &:disabled
    pointer-events: none
  &:focus
    border: 1px solid #575B5D

.spacer
  width: 24px
  height: 48px
  font-weight: 500
  font-size: 40px
  line-height: 48px
  text-align: center
  color: #191D21

.message
  color: #575B5D
  margin-bottom: 5px
  text-align: center
  &:first-of-type
    padding-top: 42px
  &:last-of-type
    margin-bottom: 40px

.form_error
  height: 40px
  background-color: #D8323C
  color: #FFFFFF
  text-align: center
  line-height: 40px
  margin-bottom: 8px
  text-transform: uppercase
  font-weight: 500
  font-size: 12px

@media screen and ( max-width: 767px )
  .input,.input:disabled
    height: 40px
    width: 40px
    font-size: 24px
    line-height: 40px
    margin: 0 4px
  .spacer
    width: 24px
    font-size: 32px
