$dgreen: #1D8975
$green: #229E87
$lgreen: #c1da5a
$yellow: #f4de00
$orange: #ffbb33
$black: #283039
$greenbg: #eaf8f4
$white: #ffffff
$red: #ff0000
$blue: #396dff

$grey_4d: #4D4C5C
$grey_63: #637381
$grey_666: #666666 //  \m/
$grey_69: #69747e
$grey_6c: #6C7074
$grey_99: #999999
$grey_b4: #B4B9BF
$grey_c1: #c1c1c1
$grey_c3: #c3c5c7
$grey_cc: #cccccc
$grey_d4: #d4d6d8
$grey_d6: #d6d6d6
$grey_e5: #e5e5e5
$grey_e7: #e7e8eb
$grey_ea: #eaeaea
$grey_f0: #f0f1f2
$grey_f2: #f2f2f2
$grey_f6: #f6f6f6
$grey_f7: #F7F8FB
$grey_f8: #f8f8f8
$grey_f9: #f9f9f9
$grey_fa: #fafafa

$grey: $grey_d6
$lgrey: $grey_f2
$mgrey: $grey_e5
$dgrey: $grey_99

$emph_orange: #f26522
$noti_orange: #e36c38
$bundle_orange: #F55D3E

$greenbg: #d6e89e

$status_red: #ff0000
$status_amber: $emph_orange
$status_green: #7fc24e

$invalid_red: #dd3333

$info_blue: #1b7ee4
$blue_1b: #1b77d5

/* Semantic: */
$link_color: $green
$link_hover: $black
$button_hover: $dgreen

$valid: $green
$invalid: #ff0000
$voucher_blue: #1B7EE4
