@import ../../styles/colors

@mixin attribute_list_item
  display: flex
  justify-content: space-between
  align-items: center
  min-height: 45px
  border-bottom: 1px solid $grey_ea

.attribute-list
  .attribute-list__item
    @include attribute_list_item
    &.no-bottom-divider
      border-bottom-color: $white

    &:last-child
      border-bottom: none

    .attribute-list__name
      padding: 12px 0
    .attribute-list__info
      font-size: 12px
      color: $grey_69

    .attribute-list__value
      display: flex
      justify-content: space-between
      align-items: center
      .edit-link
        padding-left: 5px
      .onoff_slide
        display: inline-block

        label
          line-height: 18px

      .value-label
        display: inline-block
        padding-right: 10px
      div
        display: inline-block
      svg
        margin: 0
