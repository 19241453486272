.apple-pay-button {
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: plain;
    visibility: hidden;
    display: inline-block;
    width: 200px;
    min-height: 30px;
    border: 1px solid black;
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-size: 100% calc(60% + 2px);
    background-repeat: no-repeat;
    background-color: white;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 0px;
    margin: 5px auto;
    transition: background-color .15s;
}
 
.apple-pay-button.visible {
    visibility: visible;
}
.apple-pay-button.white {
  -apple-pay-button-style: white;
}
.apple-pay-button.outline {
  -apple-pay-button-style: white-outline;
}
 
 
.apple-pay-button:active {
    background-color: rgb(152, 152, 152);
}
